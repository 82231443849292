.beige-container {
  display: grid;
  align-items: stretch;
  min-height: 705px;
  margin: 0 auto;
  background-color: #e7e5df;
  border-radius: 10px;
  text-align: center;
}

.input-wrapper {
  display: grid;
  justify-items: center;
  align-items: center;
}

.inner-wrapper {
  display: grid;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  width: min(95%, 350px);
  padding: 1rem 0;
}

.stockings-wrapper {
  display: grid;
  align-items: center;
  align-content: baseline;
  gap: 2rem;
  margin: 0 auto;
  width: min(95%, 350px);
  padding-top: 3rem;
}

.loading-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quiz-container {
  display: grid;
  gap: 2rem;
}

.instructions-container {
  display: grid;
  justify-items: center;
  gap: 1rem;
}

.controllers-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.controllers-btn {
  outline-color: transparent;
  border: 1px solid #383838;
  background-color: transparent;
  cursor: pointer;
  font-size: .9rem;
  padding: .5em;
  border-radius: 5px;
  width: 100%;
}

.link {
  color: #383838;
  font-size: 1.1rem;
  margin-top: .5rem;
}

select option {
  background-color: #e7e5df;
  color: #383838;
  display: flex;
  justify-content: center;
}

option::before {
  content: attr(value) ":";
  display:inline-block;
  padding-right:3px;
  padding-left:100px;
  font-weight:bold;
  width:1.6em;
  text-align:right;
}

.chevron {
  box-sizing: content-box;
  padding: 0.2rem 0.6rem 0 1rem;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 0.6rem;
}

.chevron svg {
  width: 1.2rem;
  height: 0.8rem;
}

/* Custom select */
.select-wrapper {
  background-color: #e7e5df;
  color: #383838;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  position: relative;
  transition: all 0.15s ease-out;
  width: 100%;
  min-width: 100px;
  border: .1rem solid #383838;
  border-radius: 4px;
  padding: .5rem 1rem;
}

.select-wrapper-small {
  padding: .5rem;
}

.select {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-appearance: none;
}

.selected-value {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
  min-width: 30px;
  padding-left: 0.6rem;
  position: relative;
  font-size: 0.9rem;
}
/* end of custom select */

.video {
  width: 100%;
  border-radius: 10px;
  width: 300px;
  height: 300px
}

.results-wrapper {
  display: grid;
}

.size-suggestion-wrapper,
.disclaimer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
}

.disclaimer {
  height: 18px;
}

@media (min-width: 1024px) {
  .video {
    width: 400px;
    height: 400px;
  }

  .inner-wrapper {
    max-width: 400px;
  }

  .beige-container {
    min-height: 760px;
  }
}

.inner-grid-wrapper {
  display: grid;
  gap: 1rem;
  margin: 0 auto;
  width: 100%;
  grid-template-areas: "a b" "c c";
}

.display-none {
  display: none !important;
}

.stockings-results-wrapper {
  display: block;
  grid-column-start: span 3;
}

.grid-area-a {
  grid-area: a;
}

.grid-area-b {
  grid-area: b;
}

.grid-area-c {
  grid-area: c;
}

.stockings-bottom-button-wrapper {
  grid-area: 3 / 2 / 4 / 3;
}

.min-h-size-wrapper {
  min-height: 3rem;
}

.input-label {
  margin-bottom: 0.5rem;
}

.stockings-title {
  align-self: start;
  font-family: 'Chronicle Display', serif;
  font-size: 2em;
  font-weight: 400;
}

.sizes {
  color: #383838;
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  gap: 0.5rem;
  justify-content: center;
  justify-items: center;
  margin-top: 0.4rem;
}

@media (min-width: 380px) {
  .inner-grid-wrapper {
    justify-content: center;
    align-items: end;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "a b c";
  }
}